'use client';

import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import Confetti from 'react-confetti';
import Alert from '@/components/ui/Alert';
import Button from '@/components/ui/Button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/Dialog';
import Spinner from '@/components/ui/Spinner';
import { useTranslations } from '@/hooks';
import { useEmailVerifyStore } from '@/store';
export function EmailVerify() {
  const searchParams = useSearchParams();
  const t = useTranslations();
  const {
    open,
    loading,
    success,
    error,
    openPopup,
    verifyEmail,
    closePopup
  } = useEmailVerifyStore();
  useEffect(() => {
    const checkToken = async () => {
      const token = searchParams.get('token');
      if (token) {
        openPopup();
        await verifyEmail(token);
      }
    };
    checkToken();
  }, [searchParams]);
  return <Dialog open={open} onOpenChange={closePopup} data-sentry-element="Dialog" data-sentry-component="EmailVerify" data-sentry-source-file="EmailVerify.jsx">
      <DialogContent size="sm" data-sentry-element="DialogContent" data-sentry-source-file="EmailVerify.jsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="EmailVerify.jsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="EmailVerify.jsx">{t('email_verification.title')}</DialogTitle>
        </DialogHeader>

        {loading && <Spinner />}

        {!loading && success && <div className="relative overflow-hidden">
            <Confetti numberOfPieces={100} recycle={true} duration_ms={3000} />
            <Alert variant="success">
              {t('email_verification.verify.status.success')}
            </Alert>
          </div>}

        {!loading && error && !success && <Alert variant="danger">
            {t('email_verification.verify.status.error')}
          </Alert>}

        <DialogFooter className="mt-4" data-sentry-element="DialogFooter" data-sentry-source-file="EmailVerify.jsx">
          <Button className="w-full" label={t('common.actions.continue')} onClick={closePopup} data-sentry-element="Button" data-sentry-source-file="EmailVerify.jsx" />
        </DialogFooter>
      </DialogContent>
    </Dialog>;
}
export default EmailVerify;