import Badge from '@/components/ui/Badge';
export const CuratorScoreBadge = ({
  label,
  value,
  icon,
  variant = 'light',
  fallbackValue,
  ...props
}) => {
  return <>
      {!value && <Badge variant={variant} icon={icon} label={label} value={fallbackValue} {...props} />}

      {value && <Badge variant={variant} icon={icon} label={label} value={value} {...props} />}
    </>;
};
export default CuratorScoreBadge;