import Icon from '@/components/ui/Icon';
import { cn } from '@/utils';
export function Variation({
  value,
  invert = false,
  unit = '%',
  className
}) {
  const classes = cn({
    badge: true,
    'badge-variation': true,
    'badge-positive': value > 0 && !invert || value < 0 && invert,
    'badge-neutral': value === 0,
    'badge-negative': value < 0 && !invert || value > 0 && invert,
    [className]: className
  });
  const getIcon = () => {
    if (value > 0) {
      return 'ArrowUp';
    } else if (value < 0) {
      return 'ArrowDown';
    }
    return 'Equal';
  };
  return <div className={classes} data-sentry-component="Variation" data-sentry-source-file="Variation.jsx">
      <Icon className="badge-icon" name={getIcon()} data-sentry-element="Icon" data-sentry-source-file="Variation.jsx" />
      {value && value}
      {unit && unit}
    </div>;
}
export default Variation;