'use client';

import { cn } from '@/utils';
export const Wrapper = ({
  className,
  children,
  id
}) => {
  const classes = cn({
    wrapper: true,
    'min-h-screen container mx-auto': true,
    [className]: className
  });
  return <div id={id} className={classes} data-sentry-component="Wrapper" data-sentry-source-file="Wrapper.jsx">
      {children}
    </div>;
};
export default Wrapper;