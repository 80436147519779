'use client'

import { useEffect, useState } from 'react'
import { matchfyApi } from '@/api'

export default function useCheckIsBlockedUser(spotifyId) {
  const [state, setState] = useState()

  useEffect(() => {
    const checkIfUserIsBlocked = async () => {
      try {
        const response = await matchfyApi.checkIfUserIsBlocked(spotifyId)
        setState(response.isBlocked)
      } catch (error) {
        console.error(error)
        setState(undefined)
      }
    }
    checkIfUserIsBlocked()
  }, [spotifyId])

  return state
}
