import { useTranslations } from 'next-intl';
import useBlockUnblockUser from './useBlockUnblockUser';
import Button from '@/components/ui/Button';
import { cn } from '@/utils';
export default function BlockUser({
  className,
  blockedBtnVariant = 'primary-light',
  btnVariant = 'red-light',
  blockedBtnClasses,
  btnClasses,
  blockedIcon = 'Lock',
  callback,
  checkOnlineStatus = false,
  disabled,
  icon = 'Lock',
  isBlockedUser,
  spotifyId,
  showLabel = true,
  showTitle = true,
  ...props
}) {
  const t = useTranslations();
  const [loading, isBlocked, toggle] = useBlockUnblockUser(spotifyId, isBlockedUser, checkOnlineStatus);
  const handleButtonClick = () => {
    toggle();
    if (callback) callback(!!isBlocked, spotifyId);
  };
  const classes = cn({
    [className]: className
  });
  const buttonClasses = cn({
    'block-user': true,
    blocked: isBlocked,
    [btnClasses]: btnClasses,
    [blockedBtnClasses]: blockedBtnClasses && isBlocked
  });
  return <div className={classes} data-sentry-component="BlockUser" data-sentry-source-file="BlockUser.jsx">
      {showTitle && <p className="mb-3">
          {t('block_user.prompt.text')}
        </p>}

      <Button className={buttonClasses} disabled={disabled} loading={loading} variant={isBlocked ? blockedBtnVariant : btnVariant} tooltip={!showLabel && t(isBlocked ? 'block_user.actions.unblock' : 'block_user.actions.block')} label={showLabel && t(isBlocked ? 'block_user.actions.unblock' : 'block_user.actions.block')} icon={isBlocked ? blockedIcon : icon} onClick={handleButtonClick} {...props} data-sentry-element="Button" data-sentry-source-file="BlockUser.jsx" />
    </div>;
}