'use client'

import { useCallback, useEffect, useState } from 'react'
import { matchfyApi } from '@/api'
import { useTranslations } from '@/hooks'
import { toast } from '@/lib'

export default function useBlockUnblockUser(
  spotifyId,
  _isBlocked = false,
  checkOnline = false
) {
  const [isBlocked, toggleBlock] = useState(_isBlocked)
  const [loading, setLoading] = useState(false)
  const t = useTranslations()

  useEffect(() => {
    const checkBlockStatus = async () => {
      if (!checkOnline) return

      try {
        const response = await matchfyApi.checkIfUserIsBlocked(spotifyId)
        toggleBlock(response.isBlocked)
      } catch (error) {
        console.error(error)
        toggleBlock(false)
      }
    }

    checkBlockStatus()
  }, [checkOnline, spotifyId])

  const blockUser = useCallback(async (id) => {
    setLoading(true)
    try {
      await matchfyApi.blockUser(id)
      toast('success', t('feedback.success.user.block'))
      toggleBlock(true)
    } catch (error) {
      console.error(error)
      toast('error', t('feedback.error.user.block'))
    } finally {
      setLoading(false)
    }
  }, [t])

  const unblockUser = useCallback(async (id) => {
    setLoading(true)
    try {
      await matchfyApi.unblockUser(id)
      toast('success', t('feedback.success.user.unblock'))
      toggleBlock(false)
    } catch (error) {
      console.error(error)
      toast('error', t('feedback.error.user.unblock'))
    } finally {
      setLoading(false)
    }
  }, [t])

  const toggle = () => {
    if (isBlocked) {
      unblockUser(spotifyId)
    } else {
      blockUser(spotifyId)
    }
  }

  return [loading, isBlocked, toggle]
}
