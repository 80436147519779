'use client';

import React, { createContext, useContext } from 'react';
import { BlockedUserPage } from '@/components/BlockUser';
import Spinner from '@/components/ui/Spinner';
import { useSession } from '@/hooks';
const BlockedUserContext = createContext();
export const useBlockedUser = () => useContext(BlockedUserContext);
export const BlockedUserProvider = ({
  children
}) => {
  const {
    data: session,
    status
  } = useSession();
  const user = session?.user || {};
  const getChildren = () => {
    if (status === 'loading') {
      return <div className="flex items-center justify-center w-full h-screen">
          <Spinner />
        </div>;
    }
    if (user?.isBlocked) {
      return <BlockedUserPage />;
    }
    return children;
  };
  return <BlockedUserContext.Provider value={{
    isBlocked: user?.isBlocked ?? false
  }} data-sentry-element="unknown" data-sentry-component="BlockedUserProvider" data-sentry-source-file="BlockedUserProvider.jsx">
      {getChildren()}
    </BlockedUserContext.Provider>;
};
export default BlockedUserProvider;