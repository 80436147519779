import { cn } from '@/utils';
const SectionTitle = ({
  title,
  emoji,
  tag = 'h1',
  subTag = 'span',
  badge,
  center = false,
  subtitle,
  children,
  headingClasses,
  subHeadingClasses,
  className
}) => {
  const classes = cn({
    'section-title': true,
    'section-title-center': center,
    [className]: className
  });
  const Heading = tag;
  const SubHeading = subTag;
  return <div className={classes} data-sentry-component="SectionTitle" data-sentry-source-file="SectionTitle.jsx">
      {emoji && emoji}

      {badge && badge}

      {title && <Heading className={cn({
      [headingClasses]: headingClasses,
      'mx-auto': center,
      'text-balance': true
    })}>
          {title}
        </Heading>}

      {subtitle && <SubHeading className={cn({
      subtitle: true,
      'text-balance': true,
      [subHeadingClasses]: subHeadingClasses
    })}>
          {subtitle}
        </SubHeading>}
      {children}
    </div>;
};
export default SectionTitle;