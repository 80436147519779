'use client'

import { useSearchParams, usePathname, useRouter } from 'next/navigation'
import { useEffect } from 'react'
import { hotjar } from 'react-hotjar'
import { matchfyApi } from '@/api'
import { useConsent } from '@/context/ConsentProvider'
import { useAnalytics, useSession, useTranslations } from '@/hooks'
import { EVENTS, trackEvent, toast } from '@/lib'
import {
  useAffiliateStore,
  useAppStore,
  useMetadataStore
} from '@/store'

export const Tracking = () => {
  const { consent } = useConsent()
  const { data: session, update } = useSession()
  const { user } = session || {}

  const pathname = usePathname()
  const router = useRouter()
  const t = useTranslations()
  const searchParams = useSearchParams()
  const { gtag, gtagPageView } = useAnalytics()

  const { completeRegistration, setCompleteRegistration, isNewUser, setIsNewUser } = useAppStore()
  const { affiliate, setAffiliate, clearAffiliate } = useAffiliateStore()
  const { setMetadata } = useMetadataStore()

  const clearParams = () => {
    router.replace(pathname, undefined, { shallow: true })
    clearAffiliate()
  }

  const handleAffiliate = async () => {
    if (!user) return
    const code = searchParams.get('code')

    try {
      // Fetch affiliate code
      if (code && code.length > 0 && affiliate?.code !== code) {
        const affiliateData = await matchfyApi.getAffiliateOrReferral(code)
        setAffiliate(affiliateData)
        toast('success', t('affiliate.applied'))
      }

      // Referred by another user
      if (user && affiliate?.userReferral) {
        await matchfyApi.useAffiliateCode(code)
        update()
        clearParams()
      }
    } catch (error) {
      clearParams()
      clearAffiliate()

      if (error?.response?.data?.message) {
        toast('error', t(error?.response?.data?.message) || error?.message)
      } else {
        toast('error', error?.message)
      }
    }
  }

  const handleUtmParams = () => {
    const utmSource = searchParams.get('utm_source')
    const utmCampaign = searchParams.get('utm_campaign')
    const utmContent = searchParams.get('utm_content')
    const gclid = searchParams.get('gclid')

    if (utmSource && utmCampaign && utmContent && gclid) {
      setMetadata({
        utmSource,
        utmCampaign,
        utmContent,
        gclid,
      })
    }
  }

  const trackNewUser = async (user) => {
    if (!user) return

    try {
      await trackEvent(EVENTS.NEW_USER, { user }, user.spotifyId)
      setCompleteRegistration(true)
      setIsNewUser(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (session && session.newUser !== isNewUser && isNewUser === null) {
      setIsNewUser(session.newUser)
    }
  }, [session])

  useEffect(() => {
    handleAffiliate()
    handleUtmParams()
  }, [searchParams, user])

  useEffect(() => {
    if (isNewUser && !completeRegistration) {
      trackNewUser(session?.user)
    }
  }, [isNewUser])

  useEffect(() => {
    if (consent) {
      if (process.env.NEXT_PUBLIC_HOTJAR_ID) {
        hotjar.initialize(process.env.NEXT_PUBLIC_HOTJAR_ID, 6)
      }

      if (gtag) {
        gtagPageView(pathname + searchParams.toString())
      }
    }
  }, [consent])
}

export default Tracking
